@import '../../../styles/customMediaQueries.css';

.root {
  & > fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  & ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 12px;

    & li {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 12px;

      margin-bottom: 12px;
      padding: 0 0 12px;
      border-bottom: 1px solid var(--colorGrey100);

      &:last-of-type {
        margin-bottom: 0;
      }

      & > div {
        order: 2;
      }
    }
  }
}

.deleteComment {
  order: 1;
  width: auto;
  min-height: auto;
  padding: 2px 6px;

  & > svg {
    margin-bottom: 2px;
  }
}

.deleteCommentSpinner {
  width: 16px;
  height: 16px;
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}

.title {
  display: flex;
  border-bottom: 1px solid var(--colorGrey100);
}

.commentInput {
  margin-top: 12px;
  margin-bottom: 24px;
}

.send {
  width: auto;
  padding: 12px 24px;
}

.allowCommentsCTA {
  margin-top: 12px;
  padding: 6px 16px;

  & > svg {
    width: 100%;
    height: 100%;
    margin-bottom: 4px;
  }
}

.loadMore {
  margin: 12px auto 24px;
}

.loadSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--colorGrey100);
}
