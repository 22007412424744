.root {
  & > nav {
    margin-bottom: 24px;
  }
}

.tab {
  &:not(:last-of-type) {
    margin-right: 12px;
  }

  & > button {
    color: var(--colorGrey700);
  }
}

.tabTitle {
  margin: 0;
}
